
import Calendar from 'primevue/calendar';
import { defineComponent, ref, toRef } from 'vue';

export default defineComponent({
    components: {
        Calendar
    },
    props: {
        editMode: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        recipientNameProp: {
            type: String,
            required: true
        },
        forProp: {
            type: String,
            required: true
        },
        createdDate: {
            type: String,
            required: true
        },
        issueDate: {
            type: String,
            required: true
        },
        isInvoice: {
            type: Boolean,
            default: false
        }
    },
    async setup(props, { emit }) {
        const recipientName = toRef(props, 'recipientNameProp');
        const forText = toRef(props, 'forProp');
        const issueDateProp = ref<Date>();
        
        function moveToNextInput(event: KeyboardEvent, nextInputId = '') {
            event.preventDefault();

            const nextInput = document.getElementById(nextInputId);
            if (nextInput) {
                (nextInput as HTMLElement).focus();
            }else {
                const currentInput = document.activeElement;
                if (currentInput) {
                    (currentInput as HTMLElement).blur(); // Unfocus the current input
                }
            }
        }

        function updateRecipientName(event: any) {
            emit('updateRecipientName', event.target.value);
        }
        function updateFor(event: any) {
            emit('updateFor', event.target.value);
        }
        function valueDateChanged(value: Date) {
            emit('valueDateChanged', value);
        }

        return { recipientName, forText, updateRecipientName, updateFor, moveToNextInput, issueDateProp, valueDateChanged };
    }
});
